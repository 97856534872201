<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type == 'edit' ? $t(`cip.plat.app.adInfo.title.editHeadTitle`) : $t(`cip.plat.app.adInfo.title.addHeadTitle`)"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {update, getDetail} from "@/api/system/adInfo";
import {mapGetters} from "vuex";


export default {
  name: "adInfoEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {

    return {
      type: 'view',
      dataForm: {},
    }
  },
  computed: {
    ...mapGetters(["language"]),
    headBtnOptions() {

      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      let validatePass = (rule, value, callback) => {
        if (value != '') {
          let val = JSON.parse(value)
          let language = this.language
          let val2 = val[language]
          if (val2.substring(0,1)==" " || val2.substring(val2.length-1)==" ") {
            callback(new Error(this.$t('cip.cmn.rule.deleteWarning')))
          }
          if (val2.length > 60) {
            callback(new Error(this.$t('cip.cmn.rule.nameLength6Warning')));
          } else {
            callback();
          }
        } else {
          callback(new Error(this.$t('cip.plat.app.adInfo.field.adName') + this.$t('cip.cmn.rule.noEmptyWarning')));
        }
      };
      return [

        {
          label: this.$t('cip.plat.app.adInfo.field.adName'),
          prop: 'adName',
          labelWidth: 130,
          span: 8,
          isGlobal:true,
          placeholder: this.$t('cip.plat.app.adInfo.field.adName'),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [{ required: true, validator: validatePass, trigger: 'blur' }]
        },
        {
          label: this.$t('cip.plat.app.adInfo.field.adType'),
          prop: 'adType',
          labelWidth: 130,
          span: 8,
          type: 'select',
          dataType: "string",
          disabled: !['edit','add'].includes(this.type),
          dicUrl: `${baseUrl}AD_INFO_TYPE`,
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') +  this.$t('cip.plat.app.adInfo.field.adType'),
              trigger: "blur"
            },
          ],
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          value:'1001',
          control:(val)=>{
            if(val==='1002'){
              return {
                filePath:{
                  display:false
                },
              }
            }else{
              return {
                filePath:{
                  display:true
                },
              }
            }
          },
        },
        {
          label: this.$t('cip.plat.app.adInfo.field.sort'),
          prop: 'sort',
          labelWidth: 130,
          span: 8,
          type: 'number',
          value: 0,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.app.adInfo.field.sort`),
        },
        {
          label: this.$t('cip.plat.app.adInfo.field.filePath'),
          prop: 'filePath',
          type: 'upload',
          labelWidth: 130,
          dataType: 'string',
          loadText: this.$t('cip.plat.app.adInfo.field.loadText'),
          propsHttp: {
            url:'link',
            name:'originalName',
            res: 'data'
          },
          limit: 1,
          accept:'image/png, image/jpeg',
          tip: this.$t('cip.plat.app.adInfo.msg.avater'),
          action: "/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform",
          display:true,
          row:true,
          readonly:!['edit','add'].includes(this.type),

        },
        {
          label: this.$t('cip.plat.app.adInfo.field.urlPath'),
          labelWidth: 130,
          maxlength: 200,
          prop: 'urlPath',
          row: true,
          span: 16,
          type: 'textarea',
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.app.adInfo.field.urlPath`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.adInfo.field.urlPath'),
              trigger: "blur"
            },
            {
              pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
              message: this.$t("cip.cmn.rule.removeSpacesWarning"),
            }
          ],
        },
        {
          label: this.$t('cip.plat.app.adInfo.field.remark'),
          labelWidth: 130,
          maxlength: 500,
          span: 16,
          prop: 'remark',
          type: 'textarea',
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.app.adInfo.field.remark`),
        },
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$loading()
          update({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close()
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
